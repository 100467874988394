<template>
  <TitleBar>Dashboard Stats</TitleBar>
  <div class="py-0 px-4">
    <div
      class="grid mt-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
    >
      <DataTable
        class="col-12"
        :value="dashboardStatsData"
        responsiveLayout="scroll"
        :lazy="true"
        :loading="tableDataIsLoading"
        :paginator="true"
        :rows-per-page-options="rowSelectionOptions"
        v-model:rows="rowsToShow"
        :totalRecords="totalRecords"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        @page="onPage($event)"
        @sort="onSort($event)"
      >
        <template #header>
          <div class="justify-content-between h-2prem">
            <Button
              type="button"
              class="p-button-primary mx-2"
              @click="openFilters"
              icon="pi pi-filter"
              label="Filter and Search"
            />
            <span style="float: right">
              <Button
                type="button"
                class="p-button-primary mx-2"
                @click="displayExport = !displayExport"
                icon="pi pi-download"
                label="Export"
              />
              <Button
                type="button"
                class="p-button-primary mx-2"
                @click="selectPresets"
                icon="pi pi-bars"
                label="Preset"
              />
              <OverlayPanel ref="presetPanel" appendTo="body">
                <div
                  v-for="preset in presets"
                  :key="preset.id"
                  class="flex-1 px-2 py-2"
                >
                  <RadioButton
                    v-model="selectedPreset"
                    :inputId="preset.id"
                    name="dynamic"
                    :value="preset"
                  />
                  <label :for="preset.id" class="ml-2">{{ preset.name }}</label>
                </div>
              </OverlayPanel>

              <Button
                type="button"
                class="p-button-primary mx-2"
                @click="toggleColumns"
                icon="pi pi-eye"
                label="Toggle Columns"
                aria:haspopup="true"
                aria-controls="overlay_panel"
              />
              <OverlayPanel ref="togglePanel" appendTo="body">
                <div
                  v-for="column in allColumns"
                  :key="column.field"
                  class="flex-1 px-2 py-2"
                >
                  <Checkbox
                    :value="column"
                    :binary="true"
                    v-model="column.toggle"
                  />
                  <label class="pl-2">{{ column.label }}</label>
                </div>
              </OverlayPanel>
            </span>
          </div>
        </template>

        <Column
          v-for="column in visibleColumns"
          :key="column.field"
          :field="column.field"
          :header="column.label"
          sortable
        ></Column>
      </DataTable>
    </div>
    <Dialog
      header="Filter Responders"
      v-model:visible="displayFilters"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="px-2 py-1">
        <div class="surface-card p-4 shadow-2 border-round p-fluid">
          <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
              <label class="font-medium text-900">Campaigns</label>
              <MultiSelect
                v-model="clonedFormFilters['campaign_id']"
                :options="filterOptions.campaigns"
                optionLabel="name"
                placeholder="Select Campaigns"
                filter
                filterPlaceholder="Search Campaigns"
              />
              <p
                class="text-primary mt-2 text-xs"
                v-if="
                  typeof clonedFormFilters['campaign_id'] !== 'undefined' &&
                  clonedFormFilters['campaign_id'].length > 0
                "
                @click="clearFilters('campaign_id')"
              >
                Clear
              </p>
            </div>
            <div class="field mb-4 col-12">
              <label class="font-medium text-900">Mailings</label>
              <MultiSelect
                v-model="clonedFormFilters['mailing_id']"
                :options="filterOptions.mailings"
                optionLabel="formatted_description"
                placeholder="Select Mailings"
              />
              <p
                class="text-primary mt-2 text-xs"
                v-if="
                  typeof clonedFormFilters['mailing_id'] !== 'undefined' &&
                  clonedFormFilters['mailing_id'].length > 0
                "
                @click="clearFilters('mailing_id')"
              >
                Clear
              </p>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          @click="closeFilters(false)"
          class="p-button-text"
        />
        <Button
          label="Apply Filters"
          icon="pi pi-check"
          @click="closeFilters(true)"
          autofocus
        />
      </template>
    </Dialog>

    <Dialog
      header="Export Responders"
      v-model:visible="displayExport"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="grid mt-4">
        <div class="col-12 lg:col-8 lg:col-offset-2 text-center">
          <div v-if="displayExportMessage">
            <p>
              Continung will export all available columns for your filtered
              results.
            </p>

            <Button @click="exportResults">Export Now</Button>
          </div>
          <div v-else-if="exportIsLoading">
            <p>Please wait while we prepare your download.</p>
            <ProgressSpinner
              style="width: 50px; height: 50px"
              strokeWidth="2"
              animationDuration="2s"
            />
          </div>
          <div v-else>
            <p>Please click the button below to download your export.</p>
            <p>
              For security purposes, this link will only be valid for 10 minutes
            </p>
            <a :href="exportLink" target="_blank" style="text-decoration: none"
              ><Button
                @click="
                  displayExport = false;
                  exportIsLoading = false;
                  displayExportMessage = true;
                "
                >Download Now</Button
              ></a
            >
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          @click="displayExport = false"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import Campaign from "@/models/Campaign";
import DashboardStats from "@/models/DashboardStats";
import DashboardStatsHeader from "@/models/DashboardStatsHeader";
import DashboardStatsPreset from "@/models/DashboardStatsPreset";
import Mailing from "@/models/Mailing";
import QueryBuilderService from "@/service/QueryBuilderService";
import ProgressSpinner from "primevue/progressspinner";
import { computed, onMounted, ref } from "vue";
export default {
  components: { TitleBar, ProgressSpinner },
  setup() {
    const rowSelectionOptions = [10, 20, 50, 100];
    const totalRecords = ref(0);
    const rowsToShow = ref(50);
    const dashboardStatsData = ref([]);
    const resultsPage = ref(1);
    const formFilters = ref([]);
    const filterOptions = ref({
      campaigns: [],
      mailings: [],
    });
    const clonedFormFilters = ref([]);
    const sortBy = ref(null);
    const togglePanel = ref();
    const displayFilters = ref(false);

    const tableDataIsLoading = ref(true);

    const allColumns = ref([]);

    const presetPanel = ref();
    const presets = ref([]);
    const selectedPreset = ref(null);

    const displayExport = ref(false);
    const displayExportMessage = ref(true);
    const exportIsLoading = ref(false);
    const exportLink = ref("");

    const visibleColumns = computed(() =>
      allColumns.value.filter((item) => item.toggle)
    );

    const toggleColumns = (event) => {
      togglePanel.value.toggle(event);
    };

    const selectPresets = (event) => {
      presetPanel.value.toggle(event);
    };

    const openFilters = () => {
      console.log(formFilters.value);
      clonedFormFilters.value = { ...formFilters.value };
      console.log("ClonedFilters");
      console.log(clonedFormFilters.value);
      displayFilters.value = true;
    };
    const closeFilters = (shouldUpdate) => {
      if (shouldUpdate) {
        formFilters.value = { ...clonedFormFilters.value };
        loadData();
      }
      displayFilters.value = false;
    };

    const clearFilters = (filter) => {
      if (filter == "all") {
        //Reset All Filters
      } else {
        if (Array.isArray(clonedFormFilters.value[filter])) {
          clonedFormFilters.value[filter] = [];
        } else {
          clonedFormFilters.value[filter] = "";
        }
      }
    };

    const loadCampaignFilterOption = async () => {
      Campaign.get().then((response) => {
        filterOptions.value.campaigns = response.data.sort((a, b) =>
          a.name.localeCompare(b.name, "en", { sensitivity: "base" })
        );
      });
    };

    const loadMailingFilterOption = async () => {
      let mailingsQuery = Mailing.orderBy("-id").limit(50);

      if (clonedFormFilters.value["campaign_id"]) {
        mailingsQuery.whereIn(
          "campaign_id",
          clonedFormFilters.value["campaign_id"].map((item) => item.id)
        );
      }

      await mailingsQuery.get().then((res) => {
        filterOptions.value.mailings = res.data;
      });
      clonedFormFilters.value["mailing_id"] = [];
    };

    const loadData = async () => {
      tableDataIsLoading.value = true;
      let qb = new QueryBuilderService(DashboardStats, {
        filters: formFilters.value,
        limit: rowsToShow.value,
        sort: sortBy.value,
        page: resultsPage.value,
      });

      try {
        let response = await qb.buildQuery().include("mailing").get();
        dashboardStatsData.value = response.data;
        console.log(dashboardStatsData.value);
        totalRecords.value = response.meta.total;
      } catch (err) {
        console.log("ERROR");
        console.log(err);
      }
      tableDataIsLoading.value = false;
    };

    const exportResults = async () => {
      displayExport.value = true;
      exportIsLoading.value = true;
      displayExportMessage.value = false;
      let qb = new QueryBuilderService(DashboardStats, {
        filters: formFilters.value,
        limit: rowsToShow.value,
        sort: sortBy.value,
        page: resultsPage.value,
      });

      await qb
        .buildQuery()
        .include("mailing")
        .params({ export: "csv" })
        .get()
        .then((res) => {
          console.log(res.data[0].url);
          exportLink.value = res.data[0].url;
          exportIsLoading.value = false;
        });
    };

    const loadAllColumns = async () => {
      let response = await DashboardStatsHeader.get();
      allColumns.value = JSON.parse(response[0].field_map).map((item) => ({
        ...item,
        toggle: false,
      }));
    };

    const loadPresets = async () => {
      let response = await DashboardStatsPreset.get();
      presets.value = response;
      selectedPreset.value = presets.value[0];
    };

    const onPage = (event) => {
      resultsPage.value = event.page + 1;
      loadData();
    };

    const onSort = (event) => {
      if ("sortOrder" in event) {
        if (event.sortOrder === -1) {
          sortBy.value = "-" + event.sortField;
        } else {
          sortBy.value = event.sortField;
        }
        //Change page back to 1 when changing sort
        resultsPage.value = 0;
        loadData();
      }
    };

    onMounted(async () => {
      await loadCampaignFilterOption();
      await loadMailingFilterOption();
      await loadData();
      await loadAllColumns();
      await loadPresets();
    });

    return {
      tableDataIsLoading,
      rowsToShow,
      rowSelectionOptions,
      totalRecords,
      dashboardStatsData,
      displayFilters,
      filterOptions,
      clonedFormFilters,
      openFilters,
      closeFilters,
      clearFilters,
      onPage,
      onSort,
      loadMailingFilterOption,
      toggleColumns,
      togglePanel,
      allColumns,
      visibleColumns,
      selectPresets,
      presetPanel,
      presets,
      selectedPreset,
      displayExport,
      displayExportMessage,
      exportIsLoading,
      exportLink,
      exportResults,
    };
  },
  watch: {
    "clonedFormFilters.campaign_id"() {
      this.loadMailingFilterOption();
    },
    selectedPreset() {
      let presetColumns = JSON.parse(this.selectedPreset.visible_columns);
      this.allColumns = this.allColumns.map((item) => ({
        ...item,
        toggle: presetColumns.includes(item.field) ? true : false,
      }));
    },
  },
};
</script>
<style>
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: --var(color-primary);
  }
  40% {
    stroke: --var(color-primary);
  }
  66% {
    stroke: --var(color-primary);
  }
  80%,
  90% {
    stroke: --var(color-primary);
  }
}
</style>
